import React, { useEffect } from "react";

import { navigate } from "@utils/navigate";
import { createReviewView, ViewComponent } from "~/views/reviews/_lib/view";

import ReviewedView from "./reviewed";

const Review: ViewComponent = (props) => {
	useEffect(() => {
		navigate("/reviews/reviewed", {
			replace: true,
		});
	}, []);

	return <ReviewedView {...props} />;
};

export default createReviewView(Review, {
	title: "Reviews",
});
